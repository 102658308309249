<script>
	import gapStyles from '$lib/styles/gap.module.css';
	import paddingStyles from '$lib/styles/padding.module.css';

	export let as = 'div';

	let className = '';
	export { className as class };

	/** @type {'left' | 'center' | 'right' | undefined} */
	export let align = undefined;

	/** @type {'top' | 'middle' | 'bottom' | undefined} */
	export let valign = undefined;

	/** @type {Gap | undefined} */
	export let gap = undefined;

	/** @type {true | number | undefined} */
	export let grow = undefined;

	/** @type {Padding | undefined} */
	export let padding = undefined;

	/** @type {Padding | undefined} */
	export let paddingTop = undefined;

	/** @type {Padding | undefined} */
	export let paddingBottom = undefined;

	/** @type {Padding | undefined} */
	export let paddingY = undefined;

	/** @type {Padding | undefined} */
	export let paddingLeft = undefined;

	/** @type {Padding | undefined} */
	export let paddingRight = undefined;

	/** @type {Padding | undefined} */
	export let paddingX = undefined;

	/** @type {Element | undefined} */
	export let element = undefined;
</script>

<svelte:element
	this={as}
	bind:this={element}
	class="stack {gapStyles.gap} {paddingStyles.padding} {className}"
	data-align={align}
	data-gap={gap}
	data-padding={padding}
	data-padding-top={paddingTop ?? paddingY}
	data-padding-bottom={paddingBottom ?? paddingY}
	data-padding-left={paddingLeft ?? paddingX}
	data-padding-right={paddingRight ?? paddingX}
	data-valign={valign}
	style:flex-grow={grow === true ? 1 : grow}
	{...$$restProps}><slot /></svelte:element
>

<style>
	.stack {
		display: flex;
		flex-direction: column;
		min-height: 0;
		min-width: 0;
	}

	.stack[data-align='left'] {
		align-items: flex-start;
	}
	.stack[data-align='center'] {
		align-items: center;
	}
	.stack[data-align='right'] {
		align-items: flex-end;
	}

	.stack[data-valign='top'] {
		justify-content: flex-start;
	}
	.stack[data-valign='middle'] {
		justify-content: center;
	}
	.stack[data-valign='bottom'] {
		justify-content: flex-end;
	}
</style>
